
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'
import * as FactcheckServices from '@/services/FactCheckService';

import { Partner, INTERACTION_TYPE_ARRAY } from "@/types";


@Component
export default class SocialInteractionsPerPartner extends Vue {
  @Prop({ required: true }) from!: Date;
  @Prop({ required: true }) to!: Date;

  partners = [] as Partner[];

  loading = true;

  requestedInteractionTypes = [
    'SOCIAL_CLICK',
    'FACEBOOK_CLICK_TROUGH',
    'TWITTER_CLICK_TROUGH'
  ]

  requestedInteractionTypesNames = [
    'Andere sociale media',
    'Facebook klik',
    'Twitter klik'
  ]

  data = {} as Record<string, { social: number, facebook: number, twitter: number, shortLinkClick: number, newsLetter: number }>;


  async fetchPartners() {
    this.partners = (await FactcheckServices.GetPartners()).filter(e => e.id !== 'UNKNOWN');
  }

  @Watch('from', { immediate: true })
  @Watch('to')
  async fetchInteractionsPerPartner() {
    this.loading = true;
    const data = await StatServices.ListInteractionsPerPartner(this.from, this.to);

    for (let item of data) {
      const partnerId = item.Partner
      console.log(partnerId)
      this.data[partnerId] = {
        social: item.SOCIAL_CLICK,
        facebook: item.FACEBOOK_CLICK_TROUGH,
        twitter: item.TWITTER_CLICK_TROUGH,
        shortLinkClick: item.SHORT_LINK_CLICK,
        newsLetter: item.NEWSLETTER_CLICK_TROUGH
      }
    }

    this.loading = false;
    this.$forceUpdate();
  }

  mounted() {
    this.fetchPartners();
    this.fetchInteractionsPerPartner();
  }
}

